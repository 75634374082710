import { format } from "date-fns";
import React, { Component } from "react";
import Button from "./base/Button";
const contentful = require("contentful");

export default class Blog extends Component {
  state = { blogPosts: [] };

  componentDidMount = async () => {
    const client = contentful.createClient({
      space: process.env.GATSBY_CONTENTFUL_SPACE,
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
    });

    try {
      const entries = await client.getEntries({
        content_type: "blogPost",
        order: "-fields.postDate",
      });

      const blogPostList: any[] = [];
      entries.items.forEach(function (entry: any) {
        blogPostList.push({
          title: entry.fields.title,
          description: entry.fields.description,
          date: entry.fields.postDate,
          mediaUrl: entry.fields.mediaImageOrVideo[0].fields.file.url,
          isVideo: entry.fields.isVideo,
          linkToDetails: entry.fields.linkToDetails,
        });
      });

      this.setState({
        blogPosts: blogPostList,
      });
    } catch (err) {}
  };

  render() {
    return (
      <div className="bg-white">
        {this.state.blogPosts.map((item, index) => (
          <div key={index} className="overflow-hidden m-10">
            <div className="relative max-w-7xl mx-auto py-16">
              <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
              <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                <div>
                  <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                    {format(new Date(item["date"]), "d MMMM yyyy")}
                  </h2>
                  <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    {item["title"]}
                  </h3>
                </div>
              </div>
              <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="relative lg:row-start-1 lg:col-start-2">
                  <svg
                    className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                    />
                  </svg>
                  <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                    <figure>
                      <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                        <img
                          className="rounded-lg shadow-lg object-cover object-center"
                          src={item["mediaUrl"]}
                          alt="Whitney leaning against a railing on a downtown street"
                          width={1184}
                          height={1376}
                        />
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0">
                  <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                    <p>{item["description"]}</p>
                    <Button
                      className="max-w-xs mt-7"
                      title="Learn More"
                      link=""
                      onClick={() =>
                        window.open(item["linkToDetails"], "_blank")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
