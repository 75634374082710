import React, { Component } from "react";
import Blogs from "../components/Blogs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import SEO from "../components/Seo";
import MembersBg from "../images/blog_bg.jpeg";

export default class MembersPage extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - Blog"
          description="DMAP (Digital Makers Asia Pacific is a team of Software Engineers and Developers that got together by participating in one or more of the APEC App Challenge events. We are aiming to build a community of professionals making an impact in the world!"
        />
        <Hero
          title="DMAP"
          titleBlue="Blog"
          description="You can find the latest news and updates about the projects we are working on and the APEC App challenges here."
          image={MembersBg}
        />
        <Blogs />
        <Footer />
      </div>
    );
  }
}
